export default {
    path: 'type-categories',
    component: { render(c) { return c('router-view') } },
    name: 'Tipos de categoría',
    redirect: 'type-categories',
    children: [
        {
            path: '',
            component: () => import('@/views/type_categories/Section'),
            meta: { title: 'Tipos de categoría', container: true, access: ['admin'] }
        },
        {
            path: 'create',
            name: 'Añadir un tipo de categoría',
            component: () => import('@/views/type_categories/Create'),
            meta: { title: 'Añadir un tipo de categoría', container: true, access: ['admin']}
        },
        {
            path: 'info/:id',
            name: 'Editar tipo de categoría',
            component: () => import('@/views/type_categories/Edit'),
            meta: { title: 'Editar tipo de categoría', container: true, access: ['admin']}
        },
    ]
}