import Vue from 'vue'
import Router from 'vue-router'

import homeRouterPaths from '@/router/home.js'
import questRouterPaths from '@/router/quest.js'
import diseasesRouterPaths from '@/router/diseases.js'
import psychologistsRouterPaths from '@/router/psychologits.js'
import categoriesRouterPaths from '@/router/categories.js'
import typeCategoriesRouterPaths from '@/router/type-categories.js'

Vue.use(Router)

function configRoutes() {
  return [
    {
      path: '/login',
      name: 'Identificación',
      component: () => import('@/views/Login'),
      meta: {container: false, access: ['admin'] }
    },
    {
      path: '',
      redirect: '',
      component: { render(c) { return c('router-view') } },
      children: [
        { path: '404', name: 'Not found', component: () => import('@/views/Page404'), container: true, access: ['admin']},
        { path: '500', name: 'Fallo del servidor', component: () => import('@/views/Page500'), container: true, access: ['admin']},
        homeRouterPaths,
        questRouterPaths,
        diseasesRouterPaths,
        psychologistsRouterPaths,
        categoriesRouterPaths,
        typeCategoriesRouterPaths,
        { path: '*', name: 'Página no encontrada', component: () => import('@/views/Page404'), container: true, access: ['admin', 'doctor'] },
      ]
    },
  ]
}

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})