<template>
  <div class="c-app">
    <TheSidebar v-if="this.$route.meta.container" />
    <CWrapper>
      <TheHeader v-if="this.$route.meta.container" />
      <ErrorAlert v-if="$store.state.errorAlert.status" :msg="$store.state.errorAlert.msg" />
      <div class="c-body">
        <main class="c-main" :class="{ 'login-main': $route.path === '/login' }">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path" />
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>
<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import ErrorAlert from "../components/Alert/ErrorAlert.vue";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    ErrorAlert
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
