export default {
    path: 'categories',
    component: { render(c) { return c('router-view') } },
    name: 'Categorías',
    redirect: 'categories',
    children: [
        {
            path: '',
            component: () => import('@/views/categories/Section'),
            meta: { title: 'Categorías', container: true, access: ['admin'] }
        },
        {
            path: 'create',
            name: 'Añadir categoría',
            component: () => import('@/views/categories/Create'),
            meta: { title: 'Añadir categoría', container: true, access: ['admin']}
        },
        {
            path: 'info/:id',
            name: 'Editar la categoría',
            component: () => import('@/views/categories/Edit'),
            meta: { title: 'Editar la categoría', container: true, access: ['admin']}
        },
    ]
}