export default {
    path: 'quest',
    component: { render(c) { return c('router-view') } },
    name: 'Cuestionarios',
    redirect: 'quest',
    children: [
        {
            path: '',
            component: () => import('@/views/quest/Section'),
            meta: { title: 'Cuestionarios', container: true, access: ['admin']}
        },
        {
            path: 'create',
            name: 'Crear cuestionario',
            component: () => import('@/views/quest/Edit'),
            meta: { title: 'Crear cuestionario', container: true, access: ['admin'] }
        },
        {
            path: 'edit/:id',
            name: 'Editar cuestionario',
            component: () => import('@/views/quest/Edit'),
            meta: { title: 'Editar cuestionario', container: true, access: ['admin'] }
        },
        {
            path: 'info/:id',
            name: 'Ver Cuestionario',
            component: () => import('@/views/quest/showQuest'),
            meta: { title: 'Ver Cuestionario', container: true, access: ['admin'] }
        }
    ]
}