/** 
 * Esta función decodePayload se encarga de decodificar el payload (carga útil) de un token JWT (JSON Web Token). 
 */
export const decodePayload = (token) => {
    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let payload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));


        if ( !checkExpiration(JSON.parse(payload).exp) ) {
            localStorage.removeItem("token")
            this.$router.push("/login")
        }        

        return JSON.parse(payload)
        
    } catch (error) {
        return false
    }
}
/** 
 * Función que comprueba la expiraciónd el token
 */
export function checkExpiration(timestampToken) {
    if ( timestampToken < Date.now()/1000 ) {
        localStorage.removeItem("token")
        this.$router.push("/login")
        return false;
    }

    return true;
}
/** 
 * Esta función tiene como objetivo verificar la validez y los permisos del usuario almacenados en un token JWT (JSON Web Token) guardado en el almacenamiento local (localStorage) de un navegador web.
 */
export function checkUser() {
    let user = decodePayload(localStorage.token);

    if ( !user ) {
        return { status: false, msg: "Salida porque no hay usuario. TODO - Borrar token y redireccionar al login" }
    }

    if ( !checkExpiration(user.exp) ) {
        return { status: false, msg: "Salida porque el token ha expirado. TODO - Borrar token y redireccionar al login" }
    }

    if (user.type === undefined) {
        return { status: false, msg: "Salida porque el usuario no esta permitido en esta página. TODO - Borrar token y redireccionar al login" }
    }

    return { status: true, user }
}
/** 
 * Esta función comprueba si el usuario es admin o no
 */
export function isAdminUser() {
    let user = decodePayload(localStorage.token);

    if (user.type === 'admin') {
        return true
    } else {
        return false;
    }
}
