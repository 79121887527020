const generateSidebarNavItem = (name, to, icon) => ({ _name: 'CSidebarNavItem', name, to, icon });

const sideBarToRender = (typeOfUser) => {
    const commonItems = [
        generateSidebarNavItem('Cuestionarios', '/quest', 'cilCheckCircle'),
        generateSidebarNavItem('Enfermedades', '/diseases', 'cilHospital'),
        generateSidebarNavItem('Categorías', '/categories', 'cilApps'),
        generateSidebarNavItem('Tipos de categorías', '/type-categories', 'cilOptions'),
    ];

    const userTypeSpecificItems = typeOfUser === 'admin'? 
    [
        generateSidebarNavItem('Psicólogos', '/psychologists', 'cilUser'),
    ]
    : 
    [];

    return [
        {
            _name: 'CSidebarNav',
            _children: [
                generateSidebarNavItem('Inicio', '/', 'cil-home'),
                {
                    _name: 'CSidebarNavTitle',
                    _children: [typeOfUser === 'admin' ? `secciones de ${typeOfUser}` : ''],
                },
                ...userTypeSpecificItems,
                {
                    _name: 'CSidebarNavTitle',
                    _children: ['secciones'],
                },
                ...commonItems,
                {
                    _name: 'CSidebarNavTitle',
                    _children: ['sesión'],
                },
                generateSidebarNavItem('Cerrar sesión', '/login', 'cilXCircle'),
            ],
        },
    ];
};

export default sideBarToRender;
