export default {
    path: 'psychologists',
    component: { render(c) { return c('router-view') } },
    name: 'Psicólogos',
    redirect: 'psychologists',
    children: [
        {
            path: '',
            component: () => import('@/views/psychologists/Section'),
            meta: { title: 'Psicólogos', container: true, access: ['admin'] }
        },
        {
            path: 'create',
            name: 'Añadir psicólogo',
            component: () => import('@/views/psychologists/Create'),
            meta: { title: 'Añadir psicólogo', container: true, access: ['admin']}
        },
        {
            path: 'info/:id',
            name: 'Editar un psicólogo',
            component: () => import('@/views/psychologists/Edit'),
            meta: { title: 'Editar un psicólogo', container: true, access: ['admin']}
        },
    ]
}