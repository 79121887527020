<template>
	<CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
		<CSidebarBrand class="d-md-down-none" to="/">
			<div class="logo-siderbar">
				<!-- <img class="logo" src="@/../public/sas-logo-nav.png" alt="Sas"> -->
				<img class="logo" src="@/../public/servicios-de-asuntos-sociales.png" alt="Servicios asuntos sociales">
			</div>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="navbar" />
		<CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
	</CSidebar>
</template>

<script>
import sideBarToRender from "./_nav";
import { decodePayload } from "@/utils/token";

export default {
	name: "TheSidebar",
	data() {
		return {
			typeOfUser: null
		}
	},
	created() {
		this.checkingTypeOfUser();
	},
	computed: {
		navbar() {
			return sideBarToRender(this.typeOfUser);
		},
		show() {
			return this.$store.state.sidebarShow;
		},
		minimize() {
			return this.$store.state.sidebarMinimize;
		},
	},
	methods: {
		checkingTypeOfUser() {
			const { type } = decodePayload(localStorage.token);
			this.typeOfUser = type;
		}
	}
};
</script>

<style>
.logo-siderbar img{
    object-fit: contain;
}
.logo-siderbar .logo{
	width: 100%;
	padding: 8px 0px;
    height: 55px;
    border-bottom: 1px solid #ccc;
}
.logo-sidebar{
	width: 100%;
    padding: 0 13px;
    height: 50px;
    margin: 8px 0;
}
.c-sidebar-minimized .logo{
	display: none;
}
.c-sidebar-minimized .logo-siderbar .logo{
	border-bottom: 0px;
}
</style>
