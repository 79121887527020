export default {
    path: 'diseases',
    component: { render(c) { return c('router-view') } },
    name: 'Enfermedades',
    redirect: 'diseases',
    children: [
        {
            path: '',
            component: () => import('@/views/diseases/Section'),
            meta: { title: 'Enfermedades', container: true, access: ['admin'] }
        },
        {
            path: 'create',
            name: 'Añadir enfermedad',
            component: () => import('@/views/diseases/Create'),
            meta: { title: 'Añadir enfermedad', container: true, access: ['admin']}
        },
        {
            path: 'info/:id',
            name: 'Editar enfermedad',
            component: () => import('@/views/diseases/Edit'),
            meta: { title: 'Editar enfermedad', container: true, access: ['admin']}
        },
    ]
}